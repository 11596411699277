






import { Component, Vue } from 'vue-property-decorator'

@Component
export default class E404 extends Vue {
  get url () {
    return this.$route.path
  }

  get xs () { return this.$vuetify.breakpoint.xs }
}
